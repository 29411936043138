.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.image {

    border-radius: 10px;


}

@media only screen and (max-width: 1200px) {
    .image {
        width: 1000px;
        height: auto;
    }
}


;

@media only screen and (max-width: 900px) {
    .image {
        width: 800px;
        height: auto;
    }
}



@media only screen and (max-width: 700px) {
    .image {
        width: 600px;
        height: auto;
    }
}

@media only screen and (max-width: 450px) {
    .image {
        width: 400px;
        height: auto;
    }
}

@media only screen and (max-width: 350px) {
    .image {
        width: 300px;
        height: auto;
    }
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}