.container {
  padding: 5%;
}

p {
  line-height: 1.5;
}

.extraLarge {
  font-size: 40px;
  text-align: center;
}

.large {
  font-size: 25px;
  margin: 5px 0;
}

.medium {
  font-size: 20px;
  margin: 5px 0;
}

.small {
  font-size: 14px;
}
