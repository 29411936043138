.navbar {
  color: var(--bg);
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  position: absolute;
  left: 30px;
}
.menuBars {
  font-size: 2rem;
}

.closeMenu {
  font-size: 2rem;
  margin-left: calc(2vw + 10px);
}

.navMenu {
  background: var(--bg);
  width: 80%;
  max-width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 800ms;
}

.backdrop {
  background-color: var(--opp);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 50;
  inset: 0;
  opacity: 0.7;
}

.navMenuActive {
  left: 0;
  transition: 250ms;
  position: fixed;
  z-index: 100;
  background: var(--bg);
  width: 80%;
  max-width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  top: 0;
}

.text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2vw;
  height: 10vh;
  list-style: none;
}

.text a {
  text-decoration: none;
  font-size: 18px;
  width: 150%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 0px;
  /* box-shadow: 1px 1px 2px 1px rgba(5, 5, 10, 0.8),
    -1px -1px 2px 1px rgba(255, 255, 255, 0.2); */
}
/* .nav-text a:hover {
  box-shadow: inset 1px 1px 2px 1px rgba(5, 5, 10, 0.8),
    inset -1px -1px 2px 1px rgba(255, 255, 255, 0.2);
} */

.text a svg {
  margin-left: 15px;
}
.navMenuItems {
  width: 100%;
}

.navMenuItems button {
  width: 150px;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  gap: 3px;
  background-color: var(--opp);
  color: var(--bg);
  margin-left: calc(2vw + 10px);
}

.navbarToggle {
  background-color: var(--bg);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
}
.span {
  margin-left: 15px;
}
