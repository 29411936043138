.container {
  padding: 10vh 5vw;
  background: var(--bg);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10vh;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-top: 10vh;
}

.button {
  background-color: var(--bg);
  border: 2px solid var(--blue);
  color: var(--blue);
  padding: 2vh 2vw;
  border-radius: 4px;
}
@media only screen and (max-width: 800px) {
  .button {
    font-size: 12px;
    padding: 10px;
  }
}