.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.card {
  width: 90%;
  max-width: 600px;
  border-radius: 15px;
  box-shadow: var(--shadow);
  background-color: var(--card-bg);
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  line-height: 1.5;
}

.h2 {
  font-size: 30px;
  text-align: center;
}
