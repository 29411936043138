.create {
  background: #6b0cd1 !important;
  background-image: -webkit-linear-gradient(top, #6b0cd1, #b82baa);
  background-image: -moz-linear-gradient(top, #6b0cd1, #b82baa);
  background-image: -ms-linear-gradient(top, #6b0cd1, #b82baa);
  background-image: -o-linear-gradient(top, #6b0cd1, #b82baa);
  background-image: linear-gradient(to bottom, #6b0cd1, #b82baa);
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  font-family: Arial;
  color: #ffffff;
  font-size: 15px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
}

.create:hover {
  background: #1a1a1a;
  background-image: -webkit-linear-gradient(top, #2b2b2b, #1a1a1a);
  background-image: -moz-linear-gradient(top, #2b2b2b, #1a1a1a);
  background-image: -ms-linear-gradient(top, #2b2b2b, #1a1a1a);
  background-image: -o-linear-gradient(top, #2b2b2b, #1a1a1a);
  background-image: linear-gradient(to bottom, #2b2b2b, #1a1a1a);
  text-decoration: none;
}
