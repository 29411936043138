* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

.app {
  background-color: purple;
  color: white;
  font-family: "Open Sans", sans-serif;
  height: 100vh;
  text-align: center;
}

.header {
  font-size: calc(20px + 2vmin);
  line-height: 2;
  text-transform: capitalize;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.timer {
  display: inline-block;
  margin-top: 25px;
  padding: 10px;
  text-align: center;
  width: 400px;
  margin-left: 25%;
}

.col-4 {
  width: 25%;
  float: left;
}

.box {
  font-weight: 300;
  padding: 10px;
}

.col-4:last-child .box {
  border-right-color: transparent;
}

.box p {
  font-size: calc(16px + 2vmin);
  margin: 0;
}

.text {
  font-size: 14px;
}

.timer-paper {
  text-align: center;
  margin: auto;
  background: var(--bg);
}

.growth {
  scroll-behavior: smooth;
}
