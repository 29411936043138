.container {
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  padding: 2%;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 450px 450px;
  column-gap: 50px;
  row-gap: 10vh;
}

@media only screen and (max-width: 800px) {
  .grid {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 5vh;
  }
}
