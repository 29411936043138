.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.card {
  width: 90%;
  max-width: 500px;
  border-radius: 15px;
  box-shadow: var(--shadow);
  background-color: var(--card-bg);
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.h2 {
  font-size: 30px;
  text-align: center;
}

.card input {
  width: 100%;
  background: none;
  padding: 1rem;
  font-size: 16px;
  border: 2px solid var(--opp);
  border-radius: 4px;
  color: var(--opp);
}

.card textarea {
  width: 100%;
  background: none;
  padding: 1rem;
  font-size: 16px;
  border: 2px solid var(--opp);
  border-radius: 4px;
  color: var(--opp);
}

.card input:is(:focus, :active, :focus-within),
.card textarea:is(:focus, :active, :focus-within) {
  outline: none;
}

.card button {
  width: 40%;
  height: 50px;
  border-radius: 4px;
  color: white;
}
