.header {
  display: flex;
  background-color: var(--bg);
  justify-content: center;
  align-items: center;
  padding: 2vh;
  border-bottom: 2px solid var(--opp);
  width: 100%;
  height: 10vh;
  position: relative;
}

.header img {
  height: 100%;
}

.button {
  width: 100px;
  height: calc(100% - 4vh);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
  gap: 3px;
  background-color: var(--opp);
  color: var(--bg);
  position: absolute;
  right: 10px;
  top: 2vh;
}

.profile {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 30px;
}

.profile p {
  font-size: 25px;
}

.purple {
  box-shadow: -1.5px -1.5px 3px 1.5px rgba(255, 255, 255, 0.25),
    1.5px 1.5px 3px 1.5px rgba(5, 5, 10, 0.75);
}

@media only screen and (max-width: 800px) {
  /* .profile {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 30%;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 5px;
  } */
}

/* .button_style {
  border-radius: 2px;
  background: #022658;

  color: #fff;

  font-weight: 500;
  font-size: 17px;
  padding: 0.7rem 1.2rem;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;
}
.header_logo {
  object-fit: contain;

  height: 15px;
}
/* img {
    width: 150px;
    height: 50px;
    justify-content: center;
    overflow: hidden;
    
    margin-top: 0px;
} */
/* .header_icon {
  color: white;
}
body {
  background-color: #778899;
}
.profile {
  display: "flex";
  justify-content: "space-between";
  width: "400px";
  color: #fff;
}
.toolbar {
  display: "flex";
  justify-content: "flex-end";
  width: "400px";
}
.userName {
  display: "flex";
  align-items: "center";
} */
@media screen and (max-width: 800px) {
  .userName {
    display: none;
  }
  .profile {
    /* flex-direction: column; */
    font-size: 15px;
    right: 5px;
    align-items: center;
  }
}
