body {
  display: flex;
  /* align-items: center;
    justify-items: center;
    justify-content: center;  */
  /* background-color: aliceblue; 
    //color: aliceblue;*/
}
.heading {
  text-align: center;
}
.Modal {
  position: absolute;
  top: 40px;
  left: 315px;

  right: 40px;
  bottom: 40px;
  background-color: papayawhip;
  overflow: scroll;
  white-space: nowrap;
}
.Modal1 {
  position: absolute;
  right: 100px;
  height: 300px !important;
  background-color: papayawhip;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
.dashboard-card {
  margin-right: 23px !important;
  margin-top: 45px;
  height: 100%;
  /* width: 930px; */
  margin-left: 280px;
  background-color: #25b8ef !important;
}
.tr1 {
  width: 100px !important;
}
.h2 {
  padding-left: 175px;
  padding-top: 30px;
  font-size: x-large;
  text-align: center;
  color: rgb(42, 102, 192);
}
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #777;
}
.tr {
  text-align: center;
}

th {
  border: 1px solid #777 !important;
  padding: 0.5rem;
  text-align: center;
  font-size: 14px;
}
.mail {
  font-size: smaller;
}
td {
  border: 1px solid #777 !important;
  padding: 0.5rem;
  text-align: center;
  font-size: 10px;
}
.in {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}
.btnn {
  /*position: absolute;*/
  /*bottom: 450px;*/ 
  color: aqua !important;
  border-radius: 15px !important;
  padding-left: 1200px !important;
  margin-right: 30px;
}
.dashboard-button {
  border-radius: 15px !important;

  padding-left: 15px;
  padding-right: 15px;
}
.view {
  margin-top: 30px;
  margin-right: 30px !important;
  margin-left: 30px !important;
}
.h6 {
  font-size: 12px;
  overflow-wrap: break-word;
}
