.leaderboardWhole {
  height: 40vh;
  width: 10vw;
  min-width: 60px;
  max-width: 100px;
  position: fixed;
  right: 2vw;
  bottom: 15vh;
  background: var(--card-bg);
  border-radius: 15px;
  z-index: 10;
  overflow-x: hidden;
  box-shadow: var(--shadow);
}

.appBarSearch1 {
  background-color: var(--card-bg);
  display: flex;
  flex-direction: column;
  padding: 2% 5%;
  gap: 10px;
  justify-content: center;
  width: max-content;
  color: var(--opp);
  border-radius: 15px;
}
.appBarSearch1 p {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}
.leaderboardButtons {
  padding: 2vw;
  width: 100%;
  height: 100%;
  box-shadow: 2.5px 2.5px 7.5px 2.5px rgba(5, 5, 10, 0.75),
    -2.5px -2.5px 7.5px 2.5px rgba(255, 255, 255, 0.25);
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.leaderboardButtons img {
  width: 100%;
}

.icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.icons button {
  width: 100%;
  height: 25%;
  background-color: var(--card-bg);
  color: var(--opp);
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

@media only screen and (max-width: 800px) {
  .leaderboardWhole {
    height: 60px;
    width: calc(100vw - (50px + 6%));
    position: fixed;
    right: 0;
    bottom: 0;
    box-shadow: none;
    max-width: none;
    border-radius: 4px;
  }
  .leaderboardButtons {
    padding: 5px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .leaderboardButtons img {
    height: 100%;
    width: auto;
  }
  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 10px;
  }
  .icons button {
    width: 100%;
    height: 100%;
    background-color: var(--card-bg);
    color: var(--opp);
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .appBarSearch1 {
    width: 90%;
  }
}
