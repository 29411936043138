.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  gap: 5vh;
}

.card {
  width: 90%;
  max-width: 700px;
  border-radius: 15px;
  box-shadow: var(--shadow);
  background-color: var(--card-bg);
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  line-height: 1.5;
}

.input {
  width: 100%;
  background: none;
  padding: 1rem !important;
  font-size: 16px !important;
  border-bottom: 1px solid var(--opp);
  color: var(--opp) !important;
}

.input:is(:focus, :active, :focus-within) {
  outline: none;
}

.button {
  width: 30%;
  height: 50px;
  background: var(--opp);
  color: var(--bg);
}

@media only screen and (max-width: 800px) {
  .container {
    padding: 5% 2%;
    gap: 2vh;
  }
}
