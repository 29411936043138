@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

* {
  box-sizing: border-box;
  margin: 0px;

  font-family: "Montserrat", sans-serif;
}
body {
  cursor: url(./components/Auth/usurp-logo-white.png), auto;
}
.content_feed,
.contest_dashboard,
.question_feed,
.profile_page {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.theme-switch {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 2%;
  left: 2%;
  border-radius: 50%;
  background-color: var(--bg);
  border: var(--opp) 2px solid;
  color: var(--opp);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.theme-switch:is(:hover, :focus) {
  background-color: var(--opp);
  border: var(--bg) 2px solid;
  color: var(--bg);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
input,
textarea,
select,
label {
  color: var(--opp) !important;
}

select {
  background: var(--bg);
}

.dialog .MuiPaper-root {
  background-color: var(--bg) !important;
}

.MuiPaper-root {
  background: inherit !important;
}

.MuiPopover-paper {
  background-color: var(--bg) !important;
}

.MuiAlert-message {
  color: var(--bg);
}

.MuiSnackbar-root {
  z-index: 2000 !important;
}

.MuiSnackbar-root .MuiPaper-root {
  background: var(--opp) !important;
}

.MuiSnackbar-root .MuiAlert-action {
  color: var(--bg);
}

.MuiSelect-root {
  color: var(--opp) !important;
}

.my-quiz svg {
  color: var(--opp) !important;
}

.MuiSnackbar-root svg {
  color: inherit !important;
}

.slider {
  height: max-content !important;
}