.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {

    border-radius: 10px;


}

@media only screen and (min-width: 1200px) {
    .image {
        width: 1000px;
        height: 600px;
    }

    .right-arrow {
        font-size: 3rem;
    }

    .left-arrow {
        font-size: 3rem;

    }
}


@media only screen and (max-width: 1200px) {
    .image {
        width: 550px;
        height: 350px;
    }

    .right-arrow {
        font-size: 2.5rem;
    }

    .left-arrow {
        font-size: 2.5rem;

    }
}


;

@media only screen and (max-width: 900px) {
    .image {
        width: 500px;
        height: 300px;
    }

    .right-arrow {
        font-size: 2rem;
    }

    .left-arrow {
        font-size: 2rem;

    }
}



@media only screen and (max-width: 700px) {
    .image {
        width: 470px;
        height: 250px;
    }

    .right-arrow {
        font-size: 2rem;
    }

    .left-arrow {
        font-size: 2rem;

    }
}

@media only screen and (max-width: 600px) {
    .image {
        width: 430px;
        height: 250px;
    }

    .right-arrow {
        font-size: 1.5rem;
    }

    .left-arrow {
        font-size: 1.5rem;

    }
}

@media only screen and (max-width: 500px) {
    .image {
        width: 340px;
        height: 200px;
    }

    .right-arrow {
        font-size: 1rem;
    }

    .left-arrow {
        font-size: 1rem;

    }
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    /* font-size: 3rem; */
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    /* font-size: 3rem; */
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}