.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
  flex-direction: column;
  gap: 80px;
}

.card {
  width: 90%;
  border-radius: 15px;
  background-color: var(--card-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: var(--shadow);
  padding: 5%;
  gap: 5px;
}

.card > h2 {
  font-size: 50px;
  text-align: center;
}

.question {
  font-size: 25px;
  color: var(--blue) !important;
}

.statement {
  line-height: 1.5;
}

.img {
  width: 100%;
  max-width: 400px !important;
}

.teambox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.teambox > div {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutimg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid var(--opp);
  margin: 10px 0;
  overflow: hidden;
}

.Question1 {
  font-size: 25px;
  font-weight: bolder;
}

@media only screen and (max-width: 800px) {
  .teambox {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }

  .teambox > div {
    width: 100%;
  }
}
.whatsapp {
  width: 60px; 
  height: 60px; 
  bottom: 30px; 
  right: 45px; 
  position: fixed; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  background-color: #25d366; 
  color: #fff; 
  border-radius: 60px;
    
}
.whatsapp:hover{
  background-color: #39faa3;
  color: #000;
}
