.container {
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100vh;
  gap: 5vw;
  justify-content: center;
}

.logo {
  width: 50%;
  height: 100%;
}

.content {
  box-shadow: var(--shadow);
  background-color: var(--card-bg);
  width: 50%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 15px;
}

.signIn {
  font-size: 30px;
}

.content form {
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content form > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--opp);
}

.content form > div > label {
  font-size: 16px;
}

.content form > div > input {
  background: none;
  border: 2px solid var(--blue);
  padding: 1rem;
  border-radius: 4px;
  font-size: 16px;
  color: var(--opp);
}

.content form > div > input:is(:focus, :active, :focus-within) {
  outline: none;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.signInButton {
  border-radius: 4px;
  background: linear-gradient(
    35deg,
    rgba(143, 5, 227, 1) 0%,
    rgba(6, 131, 255, 1) 100%
  );
  width: 100%;
  padding: 1rem;
  color: white;
  /* box-shadow: -2px -2px 6px 2px rgba(255, 255, 255, 0.25),
    2px 2px 6px 2px rgba(0, 0, 0, 0.75); */
  font-weight: 500;
  font-size: 20px;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;
}

.googleSignIn {
  border-radius: 4px;
  background: linear-gradient(
    35deg,
    rgba(143, 5, 227, 1) 0%,
    rgba(6, 131, 255, 1) 100%
  );
  width: 100%;
  padding: 1rem;
  color: white;
  font-weight: 500;
  font-size: 20px;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    gap: 2.5vh;
    padding: 2vh 0;
  }

  .logo {
    width: 100%;
    height: 30vh;
    max-width: 500px;
  }

  .content {
    width: 100%;
    max-width: 500px;
  }
}
