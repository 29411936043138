.button {
  position: fixed;
  z-index: 10;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  color: var(--opp);
  background: var(--card-bg);
  box-shadow: var(--shadow);
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 2vh;
  right: 2vw;
}

.paper {
  margin-top: 20vh;
  margin-left: 40vw;
  padding: 3vh;
  border-radius: 30px;
  position: absolute;
  background-color: var(--bg);
}

.placeButtons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.buttons1 {
  border: 2px solid var(--blue);
  border-radius: 4px;
  color: var(--opp);
  padding: 10px;
}

@media only screen and (max-width: 800px) {
  .button {
    bottom: calc(50px + 4%);
    right: 2%;
  }
}
